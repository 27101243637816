<!-- 报名缴费 -->
<template>
  <div>
    <div v-if="mainShow" id="signUp">
      <div class="main">
        <div>
          <!-- 头部地理位置和搜索框 -->
          <div class="head">
            <!-- 顶部NavBar 开始 -->
            <van-nav-bar
              :title="$t('signUp.title')"
              :left-text="$t('main.return')"
              left-arrow
              style="background-color: rgba(255,255,255,0);"
              @click-left="onClickLeft"
            />
          </div>
        </div>
        <div class="outboxstyle">
          <div style="border-radius:16px 16px 0px 0px;padding:20px">
            <div class="flex">
              <div
                v-for="item in registration.projectList"
                :key="item.id"
                class="imgBox"
                @click="selectProId(item.id)"
              >
                <img :src="item.imgUrl" alt="" class="img img-size">
                <p class="text wrapText">{{ item.projectName }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 回到顶部按钮 -->
        <v-top />
      </div>
    </div>
    <!-- <signUpLogin v-if="signUpLoginShow" @changeView="changeView()" /> -->
  </div>
</template>
<script>
// import signUpLogin from '@/pages/signUpLogin/index'
// import { Toast } from 'vant'
// checkCreateRegistrationOrder, createRegistrationOrder, getUnpaidOrder, getOrderInfoByNameListId, getSchooPageList, register, toPayOrderTrade, userRegister
import { getProjectRegistrationList, checkCreateRegistrationOrder } from '@/api/registration/projectForRegistration'
import { Toast } from 'vant'
// import { getStuNotice } from '@/api/notice'
export default {
  name: 'Siup',
  components: {
    // signUpLogin
  },
  data() {
    return {
      mainShow: true,
      signUpLoginShow: false,
      registration: {
        amount: '',
        payTypeName: '',
        clock: null,
        totalTime: 60,
        showPayMode: true,
        qrcodeUrl: '',
        schoolList: [],
        registerTemp: {
          accountNum: '',
          passwordConfirm: '',
          password: '',
          phone: '',
          schoolCode: ''
        },
        nextStep: true,
        nextPayFlag: false,
        projectList: [],
        name: '',
        idserial: '',
        diyFormList: [],
        temp: {},
        diyRules: {},
        selectProId: ''
      },
      imgTemp: [
        { imgUrl: require('../../assets/newImages/icon8.png') }
      ],
      collapseList: [
      ],
      proTypeList: [],
      activeNames: [],
      listQuery: {
        projectName: ''
      }
    }
  },
  beforeCreate() {
    // document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  beforeDestroy() {
    document.querySelector('body').setAttribute('style', 'background: #fff')
  },
  mounted() {
    this.getProjectList()
  },
  methods: {

    // 报名类缴费方法  start
    getProjectList() {
    //   this.resetTemp2()
    //   this.$nextTick(() => {
    //     this.$refs['loginForm2'].clearValidate()
    //   })
      getProjectRegistrationList().then(response => {
        this.registration.projectList = response.data.data
      })
    },
    // 点击项目
    selectProId(proId) {
      // 启用批次管理=否 报名项目的批次管理未启用，无法报名
      // 启用批次管理=是，收费项目批次配置表.启用状态=是 的数据条数=0 报名项目未配置有效批次，无法报名
      // 项目表.启用批次管理=是，收费项目批次配置表.启用状态=是 的数据条数>1
      checkCreateRegistrationOrder(proId).then((response) => {
        const res = response.data
        if (res.messageCode !== '0') {
          Toast.fail(res.message)
          return false
        }
        this.registration.selectProId = proId
      }).catch(err => {
        Toast.fail(err.message)
      })
      // 报名详情页使用
      localStorage.setItem('selectProId', proId)
      this.$router.push({ path: '/signUpLogin' })
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: 'login' })
    },
    changeView() {
      this.mainShow = true
      this.signUpLoginShow = false
    }
  }
}
</script>
<style scoped lang="scss">
.wrapText{
  line-height: 30px;
  width:80%;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:1;
  color: #2E2E2E;
  height:14px;
  font-size:14px;
}
.imgBox{
//   background: red;
  width: 25%;
  padding: 4px;
  img{
    margin: 7px auto;
    width: 46px;
    height: 46px;
  }
  .text{
    text-align: center;
    // width:60px;
    margin: 0 auto;
    height:12px;
    font-size:12px;
    font-family:STHeitiSC-Light,STHeitiSC;
    font-weight:300;
    color:rgba(46,46,46,1);
    line-height:12px;
  }
}
.title-class{
    color: #2F9FF2;
}
.border{
    border:1px solid #D0D0D0;
}
.collapse{
    border-bottom: 1px solid #D0D0D0;
}
#signUp {
  .van-cell {
  line-height: 30px;
  padding:0;
  }
  .van-icon__image {
    width: 40px;
    height: 30px;
}
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  height: 80px;
  margin-top:20px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
</style>
<style lang="scss" scoped>
.main{
  margin-bottom:50px;
}
.box{
  width: 86%;
  margin:0 auto;
  padding:0 10%;
  height:120px;
  background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
  border-radius:12px;
}
.img-box{
width:60px;
height:60px;
border:1px solid #ffffff;
background:#fff;
border-radius:50% ;
margin-top:26px;
}
.text-box{
margin-top:20px;

  p {
    font-size:14px;
    line-height: 30px;
    height: 20px;
    color: rgba(216,235,244,1);
    span{
    color: #FFFFFF;
    }
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 5px 10px;
  width: 95%;
  padding: 5px 10px !important;
}
</style>
